import './style.css';
import 'svgmap/dist/svgMap.min.css';
import 'flowbite';
import './sidebar';
import './charts';
import './map';
import './kanban';
// import './calendar';

// Have the courage to follow your heart and intuition.
